import { Dashboard } from 'components/layouts/Dashboard';
import React from 'react';
import Error404 from 'components/Error404';

function Error404Page() {
  return (
    <Dashboard title={'Page not found'}>
      <Error404 />
    </Dashboard>
  );
}

export default Error404Page;
